<template>
  <TitleBar :actions="actions" :collapseActions="false">
    Campaign Status
  </TitleBar>

  <div class="px-5 pb-5">
    <div v-if="isLoading">
      <div class="grid">
        <div
          class="col-12 lg:col-4"
          v-for="i in Array(3)"
          :key="'skeleton-' + i"
        >
          <div
            class="surface-card shadow-2 p-3 border-1 border-50 border-round"
          >
            <div class="flex justify-content-between mb-3">
              <Skeleton class="p-mb-2 h-7rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
      <div class="grid">
        <div
          class="col-12 lg:col-3"
          v-for="i in Array(4)"
          :key="'skeleton-' + i"
        >
          <div
            class="surface-card shadow-2 p-3 border-1 border-50 border-round"
          >
            <div class="flex justify-content-between mb-3">
              <Skeleton class="p-mb-2 h-12rem"></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="co in campaignOverviews" :key="co.campaign_id">
      <div v-if="co && !isLoading">
        <CampaignStats :campaign="co" />
      </div>

      <CampaignOverviewComponent :campaign="co" v-if="co && !isLoading" />
    </div>
  </div>
</template>

<script>
import CampaignOverview from "@/models/CampaignOverview";
import CampaignStats from "@/components/CampaignStats";
import CampaignOverviewComponent from "@/components/CampaignOverview";
import TitleBar from "@/components/TitleBar";
import Skeleton from "primevue/skeleton";
export default {
  components: {
    TitleBar,
    CampaignStats,
    CampaignOverviewComponent,
    Skeleton,
  },
  data() {
    return {
      campaigns: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
      campaignOverviews: [],
      actions: [],
      isLoading: true,
      pageTitle: "",
    };
  },
  async mounted() {
    this.campaigns.forEach((item) => {
      CampaignOverview.find(item)
        .then((res) => {
          res.data.name = "abc";
          this.campaignOverviews.push(res.data);
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something Went Wrong. Please Try Again",
            life: 2000,
          });
        });
    });

    this.isLoading = false;
  },
};
</script>
